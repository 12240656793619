import Layout2 from '@/layout/Layout2'

export default [{
  path: '/SoruCevap',
  redirect: '/SoruCevap/Liste',
  component: Layout2,
  children: [{
    path: 'Liste',
    name: 'SoruCevapListesi',
    meta: [{
      Roles: [1, 2],
      title: 'Soru Listesi'
    }],
    component: () => import('@/views/SoruCevap/Liste')
  }, {
    path: 'Taslak/Liste',
    name: 'TaslakSoruCevapListesi',
    meta: [{
      Roles: [1, 2],
      title: 'Taslak Listesi'
    }],
    component: () => import('@/views/SoruCevap/Liste')
  }, {
    path: 'Goruntule/:id?',
    name: 'SoruyuGoruntule',
    meta: [{
      Roles: [1, 2],
      title: 'Soruyu Görüntüle'
    }],
    component: () => import('@/views/SoruCevap/Goruntule')
  }, {
    path: 'Duzenle/:id?',
    name: 'SoruCevapDuzenle',
    meta: [{
      Roles: [1, 2],
      title: 'Soru-Cevap Düzenle'
    }],
    component: () => import('@/views/SoruCevap/Duzenle')
  }, {
    path: 'Cevapla/:id?',
    name: 'SoruyuCevapla',
    meta: [{
      Roles: [1, 2],
      title: 'Soru Cevapla'
    }],
    component: () => import('@/views/SoruCevap/Cevapla')
  }, {
    path: 'Gonder/:id?',
    name: 'SoruyuGonder',
    meta: [{
      Roles: [1, 2],
      title: 'Soruyu Gönder'
    }],
    component: () => import('@/views/SoruCevap/Gonder')
  }]
}]

import Vue from 'vue'
import App from './App'
import { store } from './store/store'
import router from './router'
import '@/permission'
import BootstrapVue from 'bootstrap-vue'

import globals from './globals'
import Popper from 'popper.js'
import CKEditor from 'ckeditor4-vue'

import VueClipboard from 'vue-clipboard2'

import Toasted from 'vue-toasted'
// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false
export const bus = new Vue()
const moment = require('moment')
require('moment/locale/tr')
Vue.use(require('vue-moment'), {
  moment
})
Vue.use(VueClipboard)
Vue.use(Toasted)
Vue.use(BootstrapVue)
Vue.use(CKEditor)
Vue.mixin({
  data: globals
})
// Global RTL flag
Vue.mixin({
  data: globals
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import Layout2 from '@/layout/Layout2'

export default [{
  path: '/Irtibat',
  redirect: '/Irtibat/Liste',
  component: Layout2,
  children: [{
    path: 'Liste',
    name: 'IrtibatListesi',
    meta: [{
      Roles: [1, 2],
      title: 'İrtibat Listesi'
    }],
    component: () => import('@/views/Irtibat/Liste')
  }, {
    path: 'EkleVeGuncelle',
    meta: [{
      Roles: [1, 2]
    }],
    component: () => import('@/components/dashboards/Dashboard1')
  }]
}]

import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@/components/NotFound'
import dashboardsRoutes from './dashboards'
import kullanici from './kullanici'
import makale from './makale'
import etiket from './etiket'
import irtibat from './irtibat'
import kategori from './kategori'
import mansetR from './mansetR'
import hataliLink from './hatalilink'
import oturumAc from '@/views/Oturum/Login'
import resimListesi from '@/views/Resim/Liste'
import sorucevap from './sorucevap'
import yorum from './yorum'
import arama from './arama'
import Layout2 from '@/layout/Layout2'

Vue.use(Router)
Vue.use(Meta)

const ROUTES = [
  { path: '', redirect: '/dashboards/dashboard' },
  { path: '/Login', name: 'OturumAc', component: oturumAc },
  { path: '/Resim/Liste', name: 'ResimListesi', component: resimListesi },
  {
    path: '/',
    component: Layout2,
    children: [{
      path: '/soru-bazli-rapor',
      name: 'SoruBazliRapor',
      meta: [{
        Roles: [1, 2],
        title: 'Soru Bazlı Rapor'
      }],
      component: () => import('@/views/SoruBazliRapor')
    }]
  },
  {
    path: '/rapor',
    component: Layout2,
    children: [{
      path: '/',
      name: 'Rapor',
      meta: [{
        Roles: [1, 2],
        title: 'Rapor'
      }],
      component: () => import('@/views/Rapor')
    }]
  },
  {
    path: '/serh',
    component: Layout2,
    children: [{
      path: '/',
      name: 'Serh',
      meta: [{
        Roles: [1, 2],
        title: 'İrtibat Listesi'
      }],
      component: () => import('@/views/Serh/Serh')
    }]
  },
  {
    path: '/fihrist',
    component: Layout2,
    children: [{
      path: '/',
      name: 'Fihrist',
      meta: [{
        Roles: [1, 2, 3],
        title: 'Fihrist'
      }],
      component: () => import('@/views/Fihrist/Fihrist')
    }]
  }
]
  .concat(dashboardsRoutes)
  .concat(etiket)
  .concat(irtibat)
  .concat(kategori)
  .concat(kullanici)
  .concat(makale)
  .concat(mansetR)
  .concat(sorucevap)
  .concat(yorum)
  .concat(arama)
  .concat(hataliLink)

ROUTES.push({
  path: '*',
  component: NotFound
})

const router = new Router({
  base: '/',
  mode: 'history',
  routes: ROUTES
})

export default router

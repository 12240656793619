export const getUserInformation = (state) => {
  return state.userInfoRO
}

export const getToken = (state) => {
  return state.tokenRO
}

export const getRefleshToken = (state) => {
  return state.refleshTokenRO
}

export const getUserAuthority = (state) => {
  return state.userAuthorityRO
}

export const getCopySubject = (state) => {
  return state.copySubject
}

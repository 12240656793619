import Layout2 from '@/layout/Layout2'

export default [{
  path: '/Makale',
  redirect: '/Makale/Liste',
  component: Layout2,
  children: [{
    path: 'Liste',
    name: 'MakaleListesi',
    meta: [{
      Roles: [1, 2],
      title: 'Makale Listesi'
    }],
    component: () => import('@/views/Makale/Liste')
  }, {
    path: 'EkleVeGuncelle/:id?',
    name: 'MakaleEkleVeGuncelle',
    meta: [{
      Roles: [1, 2],
      title: 'Makale İşlemleri'
    }],
    component: () => import('@/views/Makale/EkleVeGuncelle')
  }]
}]

import Layout2 from '@/layout/Layout2'

export default [{
  path: '/Etiket',
  redirect: '/Etiket/Liste',
  component: Layout2,
  children: [{
    path: 'Liste',
    name: 'EtiketListesi',
    meta: [{
      Roles: [1, 2],
      title: 'Etiket Listesi'
    }],
    component: () => import('@/views/Etiket/Liste')
  }, {
    path: 'EkleVeGuncelle/:id?',
    name: 'EtiketEkleVeGuncelle',
    meta: [{
      Roles: [1, 2],
      title: 'Etiket İşlemleri'
    }],
    component: () => import('@/views/Etiket/EkleVeGuncelle')
  }]
}]

<template>
    <div>
      <hata :hatalar="sayfaModelleri.hatalar" :hataVarMi="sayfaModelleri.hataVarMi"></hata>
      <basari :basarilar="sayfaModelleri.basarilar" :basariVarMi="sayfaModelleri.basariVarMi"></basari>
      <div class="container-zeybek" v-if="!sayfaModelleri.yukleniyor">
        <div class="container-grid-zeybek justify-content-center">
          <div class="container-grid-cell-zeybek" v-for="(resim, index) in resimListesi" :key="index">
            <div class="container-grid-cell-link-zeybek">
              <img :src="resim.resimYolu" :title="resim.resimAdi" class="container-grid-cell-link-image-zeybek"
                   @click="secimYap(resim.resimYolu)" />
            </div>
          </div>
        </div>
      </div>
      <b-col v-else md="12" sm="12">
        <b-alert variant="info">
          <i class="fas fa-spinner fa-spin"></i> Lütfen Bekleyiniz. Sunucudaki Resimler Getiriliyor...
        </b-alert>
      </b-col>
    </div>
</template>

<script>
import { TumunuGetir } from '../../api/resim'
import Hata from '../../components/shared/Hata'
import Basari from '../../components/shared/Basari'

export default {
  name: 'Liste',
  props: ['sayfadanSecmeMi'],
  components: {
    Hata,
    Basari
  },
  data: () => ({
    resimListesi: [],
    sayfaModelleri: {
      hatalar: [],
      hataVarMi: false,
      yukleniyor: false,
      basarilar: [],
      basariVarMi: false
    },
    soruCevapMi: false
  }),
  methods: {
    async fetchData () {
      if (this.$route.query.soruCevapMi && this.$route.query.soruCevapMi === 'true') {
        this.soruCevapMi = true
      }
      try {
        this.sayfaModelleri.yukleniyor = true
        const cevap = await TumunuGetir(this.soruCevapMi)
        this.resimListesi = cevap.data.model
        this.sayfaModelleri.yukleniyor = false
      } catch (e) {
        if (e.status === 400 || e.status === 500) {
          this.sayfaModelleri.hatalar = e.data.messages
          this.sayfaModelleri.hataVarMi = true
        } else if (e.status === 403) {
          this.sayfaModelleri.hatalar.push('Resimleri Listelemek İçin Yetkiniz Bulunmamaktadır.')
          this.sayfaModelleri.hataVarMi = true
        } else {
          this.BilinmeyenHataOlustu(e.message)
        }
        this.sayfaModelleri.yukleniyor = false
      }
    },
    BilinmeyenHataOlustu (message) {
      this.sayfaModelleri.hatalar.push('Bilinmeyen Bir Hata Oluştu. ' + message)
      this.sayfaModelleri.hataVarMi = true
    },
    secimYap (value) {
      if (this.sayfadanSecmeMi === 'true') {
        this.$emit('resimSecildi', value)
      } else {
        var funcNum = this.getUrlParam('CKEditorFuncNum')
        window.opener.CKEDITOR.tools.callFunction(funcNum, value)
        window.close()
      }
    },
    getUrlParam (paramName) {
      var reParam = new RegExp('(?:[\\?&]|&)' + paramName + '=([^&]+)', 'i')
      var match = window.location.search.match(reParam)
      return (match && match.length > 1) ? match[1] : null
    }
  },
  async mounted () {
    await this.fetchData()
  }
}
</script>

<style scoped>
  .container-zeybek {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 1rem;
  }
  .container-grid-cell-zeybek {
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .container-grid-cell-link-zeybek {
    position: inherit;
    float: left;
    overflow: hidden;
    width: 220px;
    height: 350px;
    border-radius: 10%;
    border: 1px solid #999;
  }
  .container-grid-cell-link-image-zeybek {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 15%;
  }
  .container-grid-cell-button-zeybek {
    margin-top: 8px;
    width: 80px;
    align-self: center;
  }
  @media screen and (min-width: 600px) {
    .container-grid-zeybek {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }
    .container-grid-cell-zeybek {
      width: 50%;
      width: calc(50% - 2rem);
    }
  }
  @media screen and (min-width: 1000px) {
    .container-grid-cell-zeybek {
      width: calc(100% / 4);
      width: calc(25% - 2rem);
      height: 100%;
    }
  }
</style>

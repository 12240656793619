<template>
  <div>
    <div class="row" v-if="hataVarMi">
      <div class="col-md-12">
        <div class="alert alert-danger">
          <ul>
            <li v-for="(hata, index) in hatalar" :key="index">
             <i class="fa fa-exclamation-circle"></i> {{ hata }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hata',
  props: ['hatalar', 'hataVarMi']
}
</script>

<style scoped>
 li{
   list-style-type: none;
 }
  ul{
    padding-left: 16px;
  }
</style>

import router from './router'
import globals from '@/globals'
import { getToken } from '@/utils/auth'
import { store } from './store/store'
const whiteList = ['/Login']

router.afterEach((to, from) => {
  const splashScreen = document.querySelector('.app-splash-screen')
  if (splashScreen) {
    splashScreen.style.opacity = 0
    setTimeout(() => splashScreen && splashScreen.parentNode.removeChild(splashScreen), 300)
  }
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  const yetki = store.getters.getUserAuthority
  if (to.meta[0] && to.meta[0].title) {
    document.title = to.meta[0].title
  }
  if (to.path === 'swagger') {
    document.body.classList.add('app-loading')
    setTimeout(() => next(), 10)
  } else {
    if (to.path) {
      if (to.path === '/404') {
        document.body.classList.add('app-loading')
        setTimeout(() => next(), 10)
      } else {
        if (getToken('refleshTokenRO') && getToken('userAuthorityRO')) {
          if (yetki !== null && (yetki.toString() === '3' || yetki.toString() === '2' || yetki.toString() === '1')) {
            if (to.path === '/Login') {
              document.body.classList.add('app-loading')
              setTimeout(() => next({ path: '/' }), 10)
            } else {
              if (to.meta && to.meta[0]) {
                const yetkiler = to.meta[0].Roles
                if (yetkiler) {
                  if (yetkiler.indexOf(parseInt(yetki)) !== -1) {
                    document.body.classList.add('app-loading')
                    setTimeout(() => next(), 10)
                  } else {
                    document.body.classList.add('app-loading')
                    setTimeout(() => next({ path: '/' }), 10)
                  }
                } else {
                  document.body.classList.add('app-loading')
                  setTimeout(() => next(), 10)
                }
              } else {
                document.body.classList.add('app-loading')
                setTimeout(() => next(), 10)
              }
            }
          } else {
            if (whiteList.indexOf(to.path) !== -1) {
              document.body.classList.add('app-loading')
              setTimeout(() => next(true), 10)
            } else {
              document.body.classList.add('app-loading')
              setTimeout(() => next({ path: '/Login', query: { returnUrl: to.path } }), 10)
            }
          }
        } else {
          if (whiteList.indexOf(to.path) !== -1) {
            document.body.classList.add('app-loading')
            setTimeout(() => next(true), 10)
          } else {
            document.body.classList.add('app-loading')
            setTimeout(() => next({ path: '/Login', query: { returnUrl: to.path } }), 10)
          }
        }
      }
    } else {
      document.body.classList.add('app-loading')
      setTimeout(() => next({ path: '/404' }), 10)
    }
  }
})

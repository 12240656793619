import request from '@/utils/request.js'

export function Getir () {
  return request({
    url: `/Admin/Getir`,
    method: 'get'
  })
}

export function NavGetir () {
  return request({
    url: `/Admin/NavGetir`,
    method: 'get'
  })
}

export function VersionCheck () {
  return request({
    url: `/admin/VersionCheck`,
    method: 'get'
  })
}

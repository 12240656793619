import request from '@/utils/request.js'

export function OturumAc (data) {
  return request({
    url: `/Oturum/OturumAc`,
    method: 'post',
    data
  })
}

export function OturumYenile (refleshToken) {
  return request({
    url: `/Oturum/OturumYenile`,
    method: 'get',
    params: {
      refleshToken
    }
  })
}

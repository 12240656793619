<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-corporate.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>

<script>
import { VersionCheck } from './api/admin'
import { getToken, setToken } from './utils/auth'

export default {
  name: 'app',
  metaInfo: {
    title: 'Index',
    titleTemplate: '%s - Risale Online'
  },
  updated () {
    // Remove loading state
    setTimeout(() => document.body.classList.remove('app-loading'), 1)
  },
  async created () {
    try {
      const result = await VersionCheck()
      console.log('API Front-End Version', result.data.frontendVersion)
      const localVersion = getToken('frontend-version')
      console.log('Local Front-End Version', localVersion)
      console.log(localVersion)
      if (!localVersion) {
        setToken('frontend-version', result.data.frontendVersion, 8760)
        location.reload()
      }
      if (result.data.frontendVersion !== localVersion) {
        setToken('frontend-version', result.data.frontendVersion, 8760)
        location.reload()
      }
      console.log('Time', new Date())
    } catch (e) {
      console.log(e)
    }
  }
}
</script>

<style>
.bv-no-focus-ring {
  width: 100%;
}
.toast-body {
  white-space: pre-line !important;
}
[role="group"] {
  width: 100% !important;
}
</style>

import Layout2 from '@/layout/Layout2'

export default [{
  path: '/Arama',
  redirect: '/Arama/Liste',
  component: Layout2,
  children: [{
    path: 'Liste',
    name: 'AramaListesi',
    meta: [{
      Roles: [1, 2],
      title: 'Arama'
    }],
    component: () => import('@/views/Arama/Liste')
  }]
}]

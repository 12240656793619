<template>
  <sidenav :orientation="orientation" :class="curClasses">

    <div class="app-brand demo" v-if="orientation !== 'horizontal'">
      <span class="app-brand-logo bg-primary">
        <img src="/favicon.ico" style="background-color: white">
      </span>
      <router-link to="/" class="app-brand-text demo sidenav-text font-weight-normal ml-2">Risale Online</router-link>
      <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large ml-auto"
         @click="toggleSidenav()">
        <i class="ion ion-md-menu align-middle"></i>
      </a>
    </div>
    <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>

    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
      <sidenav-router-link v-if="$store.getters.getUserAuthority.toString() === '2'" icon="fas fa-user" to="/kullanici"
                           :exact="true">Kullanıcılar
      </sidenav-router-link>

      <!-- Tables -->
      <sidenav-menu v-if="$store.getters.getUserAuthority.toString() !== '3'" icon="fas fa-question"
                    :active="isMenuActive('/SoruCevap/Liste')|| isMenuActive('/SoruCevap/Taslak/Liste') || isMenuActive('/Yorum/Liste')"
                    :open="isMenuOpen('/SoruCevap/Liste') || isMenuOpen('/SoruCevap/Taslak/Liste') || isMenuOpen('/Yorum/Liste')">
        <template slot="link-text">Soru Cevap</template>
        <sidenav-router-link icon="fas fa-question" to="/SoruCevap" :exact="true"
                             :active="isMenuActive('/SoruCevap/Liste')"
                             :open="isMenuOpen('/SoruCevap/Liste')">Sorular
          <span v-if="model.cevapsizSoruSayisi !== 0">({{ model.cevapsizSoruSayisi }})</span></sidenav-router-link>
        <sidenav-router-link icon="fas fa-save" to="/SoruCevap/Taslak/Liste" :exact="true"
                             :active="isMenuActive('/SoruCevap/Taslak/Liste')"
                             :open="isMenuOpen('/SoruCevap/Taslak/Liste')">Taslaklar
          <span v-if="model.taslakSoruSayisi !== 0">({{ model.taslakSoruSayisi }})</span></sidenav-router-link>
        <sidenav-router-link icon="fas fa-comment" :active="isMenuActive('/Yorum/Liste')" to="/Yorum"
                             :exact="true">Yorumlar
          <span v-if="model.onayBekleyenYorumSayisi !== 0">({{ model.onayBekleyenYorumSayisi }})</span>
        </sidenav-router-link>
      </sidenav-menu>
      <sidenav-router-link v-if="$store.getters.getUserAuthority.toString() !== '3'" icon="fas fa-pen" :active="isMenuActive('/Makale/Liste')" to="/makale"
                           :exact="true">Makaleler
      </sidenav-router-link>
      <sidenav-router-link v-if="$store.getters.getUserAuthority.toString() !== '3'" icon="fas fa-newspaper" to="/Manset" :active="isMenuActive('/Manset/Liste')"
                           :exact="true">Manşetler
      </sidenav-router-link>
      <sidenav-router-link v-if="$store.getters.getUserAuthority.toString() !== '3'" icon="fas fa-tag" to="/Etiket" :active="isMenuActive('/Etiket/Liste')" :exact="true">
        Etiketler
      </sidenav-router-link>
      <sidenav-router-link v-if="$store.getters.getUserAuthority.toString() !== '3'" icon="ion ion-md-cube" to="/Kategori" :active="isMenuActive('/Kategori/Liste')"
                           :exact="true">Kategoriler
      </sidenav-router-link>
      <sidenav-router-link v-if="$store.getters.getUserAuthority.toString() !== '3'" icon="fas fa-envelope-open" to="/Irtibat" :active="isMenuActive('/Irtibat/Liste')"
                           :exact="true">İrtibatlar
      </sidenav-router-link>
      <sidenav-router-link v-if="$store.getters.getUserAuthority.toString() !== '3'" icon="fas fa-file" :active="isMenuActive('/rapor')" to="/rapor"
                           :exact="true">Rapor
      </sidenav-router-link>
      <sidenav-router-link v-if="$store.getters.getUserAuthority.toString() === '3'" icon="fas fa-book" to="/fihrist"
                           :exact="true">Fihrist
      </sidenav-router-link>

    </div>
  </sidenav>
</template>

<script>
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider
} from '@/vendor/libs/sidenav'
import { NavGetir } from '../api/admin'

export default {
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },
  data: () => ({
    model: {
      cevapsizSoruSayisi: 0,
      taslakSoruSayisi: 0,
      onayBekleyenYorumSayisi: 0
    }
  }),
  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },

  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },

    async GetData () {
      try {
        var cevap = await NavGetir()
        this.model = cevap.data.model
      } catch (e) {
        console.log(e)
      }
    }
  },
  async created () {
    var a = this
    setTimeout(async function () {
      await a.GetData()
    }, 5000)
  }
}
</script>

import Layout2 from '@/layout/Layout2'

export default [{
  path: '/Manset',
  redirect: '/Manset/Liste',
  component: Layout2,
  children: [{
    path: 'Liste',
    name: 'MansetListesi',
    meta: [{
      Roles: [1, 2],
      title: 'Manşet Listesi'
    }],
    component: () => import('@/views/Manset/Liste')
  }, {
    path: 'EkleVeGuncelle/:id?',
    name: 'MansetEkleVeGuncelle',
    meta: [{
      Roles: [1, 2],
      title: 'Manşet İşlemleri'
    }],
    component: () => import('@/views/Manset/EkleVeGuncelle')
  }]
}]

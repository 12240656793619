import Layout2 from '@/layout/Layout2'

export default [{
  path: '/Kullanici',
  redirect: '/Kullanici/Liste',
  component: Layout2,
  children: [{
    path: 'Liste',
    name: 'KullaniciListesi',
    metaInfo: {
      title: 'Kullanıcı Listesi'
    },
    meta: [{
      Roles: [2],
      title: 'Kullanıcı Listesi'
    }],
    component: () => import('@/views/Kullanici/Liste')
  }, {
    path: 'EkleVeGuncelle/:id?',
    name: 'KullaniciEkleVeGuncelle',
    meta: [{
      Roles: [2],
      title: 'Kullanıcı İşlemleri'
    }],
    component: () => import('@/views/Kullanici/EkleVeGuncelle')
  }]
}]

import request from '@/utils/request.js'

export function TumunuGetir (soruCevapMi = false) {
  return request({
    url: `/Resim/TumunuGetir`,
    method: 'get',
    params: {
      soruCevapMi
    }
  })
}

import Layout2 from '../layout/Layout2'

export default [{
  path: '/HataliLink',
  redirect: '/HataliLink/Liste',
  component: Layout2,
  children: [{
    path: 'Liste',
    name: 'HataliLinkListesi',
    meta: [{
      Roles: [1, 2],
      title: 'Hatlaı Link Listesi'
    }],
    component: () => import('@/views/HataliLink')
  }]
}]

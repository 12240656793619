import axios from 'axios'
import { getToken, removeToken } from './auth'
import { OturumYenile } from '../api/login'
import { store } from '@/store/store.js'

const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:50386' : process.env.VUE_APP_API_URL,
  timeout: 0
})

service.interceptors.request.use(
  async config => {
    config.headers.post['Content-Type'] = 'application/json multipart/form-data'
    config.headers.common['Authorization'] = 'Bearer ' + getToken('tokenRO')
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    return Promise.resolve(response)
  },
  async error => {
    const { status: statusKod } = error.response
    if (statusKod === 401) {
      if (getToken('refleshTokenRO')) {
        try {
          var cevap = await OturumYenile(getToken('refleshTokenRO'))
          if (cevap.status === 200) {
            store.dispatch('setRefleshTokenData', cevap.data.model.refleshToken)
            store.dispatch('setTokenData', cevap.data.model.token)
            store.dispatch('setUserInformationData', cevap.data.model.kullaniciAdiSoyadi)
            store.dispatch('setUserAuthorityData', cevap.data.model.yetki)
            store.dispatch('setTokenExpiredData', cevap.data.model.tokenSonGecerlilik)
            error.config.headers.Authorization = 'Bearer ' + getToken('tokenRO')
            return axios.request(error.config)
          } else {
            removeToken('refleshTokenRO')
            removeToken('tokenExpiredRO')
            removeToken('tokenRO')
            removeToken('userAuthorityRO')
            removeToken('userInfoRO')
            location.reload()
          }
        } catch (e) {
          removeToken('refleshTokenRO')
          removeToken('tokenExpiredRO')
          removeToken('tokenRO')
          removeToken('userAuthorityRO')
          removeToken('userInfoRO')
          location.reload()
        }
      } else {
        removeToken('refleshTokenRO')
        removeToken('tokenExpiredRO')
        removeToken('tokenRO')
        removeToken('userAuthorityRO')
        removeToken('userInfoRO')
        location.reload()
      }
    } else {
      return Promise.reject(error.response)
    }
  }
)

export default service

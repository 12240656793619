import Layout2 from '@/layout/Layout2'

export default [{
  path: '/Yorum',
  redirect: '/Yorum/Liste',
  component: Layout2,
  children: [{
    path: 'Liste',
    name: 'YorumListesi',
    meta: [{
      Roles: [1, 2],
      title: 'Yorum Listesi'
    }],
    component: () => import('@/views/Yorum/Liste')
  }]
}]

import Layout2 from '@/layout/Layout2'

export default [{
  path: '/Kategori',
  redirect: '/Kategori/Liste',
  component: Layout2,
  children: [{
    path: 'Liste',
    name: 'KategoriListesi',
    meta: [{
      Roles: [1, 2],
      title: 'Kategori Listesi'
    }],
    component: () => import('@/views/Kategori/Liste')
  }, {
    path: 'EkleVeGuncelle/:id?',
    name: 'KategoriEkleVeGuncelle',
    meta: [{
      Roles: [1, 2],
      title: 'Kategori İşlemleri'
    }],
    component: () => import('@/views/Kategori/EkleVeGuncelle')
  }]
}]

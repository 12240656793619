<template>
  <div>
    <div class="row" v-if="basariVarMi">
      <div class="col-md-12">
        <div class="alert alert-success">
          <ul>
            <li v-for="(basari, index) in basarilar" :key="index">
              <i class="fa fa-check-circle"></i> {{ basari }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Basari',
  props: ['basarilar', 'basariVarMi']
}
</script>

<style scoped>
  li{
    list-style-type: none;
  }
  ul{
    padding-left: 16px;
  }
</style>

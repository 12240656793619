export const setRefleshToken = (state, payload) => {
  state.refleshTokenRO = payload
}

export const setToken = (state, payload) => {
  state.tokenRO = payload
}

export const setUserInformation = (state, payload) => {
  state.userInfoRO = payload
}

export const setUserAuthority = (state, payload) => {
  state.userAuthorityRO = payload
}

export const setTokenExpired = (state, payload) => {
  state.tokenExpiredRO = payload
}

export const setCopySubject = (state, payload) => {
  state.copySubject = payload
}

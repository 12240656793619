<template>
  <div class="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4"
       :style="`background-image: url('${publicUrl}img/bg/1.jpg');`">
    <div class="ui-bg-overlay bg-dark opacity-25"></div>
    <div class="authentication-inner py-5">
      <b-card no-body>
        <div class="p-4 p-sm-5">
          <!-- Logo -->
          <div class="d-flex justify-content-center align-items-center pb-2 mb-4 text-center">
            <div class="text-center">
              <div class="text-center">
                <img src="/img/rologo.png"/>
              </div>
            </div>
          </div>
          <h5 class="text-center text-muted font-weight-normal mb-4">Risale Online Admin</h5>
          <hata :hatalar="sayfaModelleri.hatalar" :hataVarMi="sayfaModelleri.hataVarMi"></hata>
          <form>
            <b-form-group label="Email Veya Kullanıcı Adı">
              <b-input v-model="oturumModel.kullaniciAdi"/>
            </b-form-group>
            <b-form-group>
              <div slot="label" class="d-flex justify-content-between align-items-end">
                <div>Şifre</div>
              </div>
              <b-input type="password" v-model="oturumModel.sifre"/>
            </b-form-group>
            <div class="d-flex justify-content-center align-items-center m-0">
<!--              <b-btn variant="primary" @click="OturumAc">Oturum Aç</b-btn>-->
              <ladda-btn :loading="sayfaModelleri.yukleniyor" @click.native="startLoading()"
                         data-style="slide-right"
                         class="btn btn-info">
                Oturum Aç</ladda-btn>
            </div>
          </form>
        </div>
      </b-card>
    </div>
  </div>
</template>

<style src="@/vendor/styles/pages/authentication.scss" lang="scss"></style>
<script>
import { OturumAc } from '../../api/login'
import Hata from '@/components/shared/Hata'
import LaddaBtn from '@/vendor/libs/ladda/Ladda'
export default {
  name: 'pages-authentication-login-v2',
  metaInfo: {
    title: 'Oturum Aç'
  },
  components: {
    Hata,
    LaddaBtn
  },
  data: () => ({
    oturumModel: {
      kullaniciAdi: null,
      sifre: null
    },
    sayfaModelleri: {
      hatalar: [],
      hataVarMi: false,
      yukleniyor: false
    }
  }),
  methods: {
    async OturumAc () {
      var burasi = this
      try {
        this.Temizle()
        var cevap = await OturumAc(this.oturumModel)
        if (cevap.data.model.yetki === 1 || cevap.data.model.yetki === 2 || cevap.data.model.yetki === 3) {
          await this.$store.dispatch('setRefleshTokenData', cevap.data.model.refleshToken)
          await this.$store.dispatch('setTokenData', cevap.data.model.token)
          await this.$store.dispatch('setUserInformationData', cevap.data.model.kullaniciAdiSoyadi)
          await this.$store.dispatch('setUserAuthorityData', cevap.data.model.yetki)
          await this.$store.dispatch('setTokenExpiredData', cevap.data.model.tokenSonGecerlilik)
          if (cevap.data.model.yetki === 3) {
            await this.$router.push('/fihrist')
            return
          }
          if (this.$route.query.returnUrl) {
            await this.$router.push(this.$route.query.returnUrl || '/')
          } else {
            await burasi.$router.push({ path: '/' })
          }
        } else {
          this.YetkiYok()
        }
      } catch (e) {
        if (e.status === 400 || e.status === 500) {
          this.sayfaModelleri.hatalar = e.data.messages
          this.sayfaModelleri.hataVarMi = true
          this.sayfaModelleri.yukleniyor = false
        } else {
          this.BilinmeyenHataOlustu(e.message)
        }
      }
    },
    Temizle () {
      this.sayfaModelleri.hataVarMi = false
      this.sayfaModelleri.hatalar = []
    },
    BilinmeyenHataOlustu (message) {
      this.sayfaModelleri.hatalar.push('Bilinmeyen Bir Hata Oluştu. ' + message)
      this.sayfaModelleri.hataVarMi = true
      this.sayfaModelleri.yukleniyor = false
    },
    YetkiYok () {
      this.sayfaModelleri.hatalar.push('Admin Modülüne Girmek İçin Yetkiniz Bulunamamaktadır.')
      this.sayfaModelleri.hataVarMi = true
      this.sayfaModelleri.yukleniyor = false
    },
    async startLoading () {
      if (this.sayfaModelleri.yukleniyor) return
      this.sayfaModelleri.yukleniyor = true
      await this.OturumAc()
    }
  }
}
</script>

import { getToken, setToken } from '../utils/auth'

export const setRefleshTokenData = ({ commit }, payload) => {
  setToken('refleshTokenRO', payload)
  commit('setRefleshToken', payload)
}

export const setTokenData = ({ commit }, payload) => {
  setToken('tokenRO', payload)
  commit('setToken', payload)
}

export const setUserInformationData = ({ commit }, payload) => {
  setToken('userInfoRO', payload)
  commit('setUserInformation', payload)
}

export const setUserAuthorityData = ({ commit }, payload) => {
  setToken('userAuthorityRO', payload)
  commit('setUserAuthority', payload)
}

export const setTokenExpiredData = ({ commit }, payload) => {
  setToken('tokenExpiredRO', payload)
  commit('setTokenExpired', payload)
}

export const setCopySubjectData = ({ commit }, payload) => {
  var eskiDeger = JSON.parse(getToken('copySubjectRO') != null ? getToken('copySubjectRO') : JSON.stringify([]))
  var a = eskiDeger.filter(d => d.id === payload.id)
  if (a.length === 0) {
    eskiDeger.push(payload)
    setToken('copySubjectRO', JSON.stringify(eskiDeger), '1h')
    commit('setCopySubject', eskiDeger)
  }
}
